import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AssetContext } from '../contexts/AssetListContext.js';

import DefectListSelect from '../components/DefectListSelect.js';
import CustomDropdown from '../components/Dropdown.js';
import ExtremeAlert from '../components/ExtremeAlert.js';

import selectDefect from '../assets/icons/select_defect_list.svg';
import propertyFilter from '../assets/icons/property_filter.svg';
import filterClose from '../assets/icons/close_filter_icon.svg';
import backIcon from '../assets/icons/navigate_before.svg'
import { RiskIcon, RiskRateSection } from '../components/RiskRateSection.js';

function AssetDetails() {
  const { asset_id: urlAssetId } = useParams(); // Get asset_id from URL
  const { assets, defects } = useContext(AssetContext);
  const navigate = useNavigate();
  const [extremeCount, setExtremeCount] = useState(0); // State to count the "Extreme"
  const [showAlert, setShowAlert] = useState(false); // State to show the alert
  const [selectedAssetId, setSelectedAssetId] = useState(urlAssetId || '');
  const [selectedAssetName, setSelectedAssetName] = useState('');
  const [selectedAssetPhoto, setSelectedAssetPhoto] = useState('');
  const [showDropdown, setShowDropdown] = useState(false); // State for dropdown visibility
  const [filteredDefects, setFilteredDefects] = useState([]); // State for filtered defects
  const [selectedRiskRate, setSelectedRiskRate] = useState(''); // State for selected Risk Rate
  const [selectedDefectTypes, setSelectedDefectTypes] = useState([]); // State for selected Defect Types
  const [defectTypes, setDefectTypes] = useState([]); // State for unique Defect Types

  useEffect(() => {
    if (selectedAssetId) {
      // Filter defects for the selected asset_id
      const filtered = defects.filter(defect => defect.asset_id === selectedAssetId);
      setFilteredDefects(filtered);

      // Count the number of "Extreme" defects
      const extremeDefectsCount = filtered.filter(defect => defect.risk_rate === 'Very High').length;
      setExtremeCount(extremeDefectsCount);

      // Show alert if there are extreme defects
      setShowAlert(extremeDefectsCount > 0);

      // Set the name and photo of the selected asset
      const selectedAsset = assets.find(asset => asset.asset_id === selectedAssetId);
      setSelectedAssetName(selectedAsset ? selectedAsset.asset_name : '');
      setSelectedAssetPhoto(selectedAsset ? selectedAsset.asset_photo : '');

      // Extract unique defect types for filtering
      const uniqueDefectTypes = [...new Set(filtered.map(defect => defect.defect_type))];
      setDefectTypes(uniqueDefectTypes);
    }
  }, [selectedAssetId, defects, assets]);

  const handleAssetSelection = (selectedPoint) => {
    setSelectedAssetId(selectedPoint.asset_id);
    setSelectedAssetName(selectedPoint.asset_name);
    navigate(`/asset-details/${selectedPoint.asset_id}`); // Update URL with the selected asset_id
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleImageClick = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    let filtered = [...defects];

    if (name === 'defect_id' && value) {
      filtered = filtered.filter(defect => defect.defect_id.toString() === value);
    }

    if (name === 'risk_rate') {
      // Check if the selected risk rate is the same as the currently selected one
      if (value === selectedRiskRate) {
        // Reset the risk rate filter
        setSelectedRiskRate('');
      } else {
        // Set the selected risk rate
        setSelectedRiskRate(value);
      }
    }

    // Filter by selected defect types
    if (selectedDefectTypes.length > 0) {
      filtered = filtered.filter(defect => selectedDefectTypes.includes(defect.defect_type));
    }

    // Filter only defects for the selected asset_id
    filtered = filtered.filter(defect => defect.asset_id === selectedAssetId);

    setFilteredDefects(filtered);
  };

  const handleDefectTypeChange = (defectType) => {
    const updatedDefectTypes = selectedDefectTypes.includes(defectType)
      ? selectedDefectTypes.filter(type => type !== defectType)
      : [...selectedDefectTypes, defectType];

    setSelectedDefectTypes(updatedDefectTypes);
  };

  const handleResetAll = () => {
    setSelectedRiskRate('');
    setSelectedDefectTypes([]);
    setFilteredDefects(defects);
  };

  // Update filtered defects based on selected defect types and risk rate
  useEffect(() => {
    let filtered = defects.filter(defect => defect.asset_id === selectedAssetId);

    // Filter by risk rate
    if (selectedRiskRate) {
      filtered = filtered.filter(defect => defect.risk_rate === selectedRiskRate);
    }

    // Extract and filter defect_types based on the selected risk_rate
    const uniqueDefectTypes = [...new Set(filtered.map(defect => defect.defect_type))];
    setDefectTypes(uniqueDefectTypes);

    // Filter by selected defect types
    if (selectedDefectTypes.length > 0) {
      filtered = filtered.filter(defect => selectedDefectTypes.includes(defect.defect_type));
    }

    setFilteredDefects(filtered);
  }, [selectedRiskRate, selectedDefectTypes, defects, selectedAssetId]);

  // Back button
  const goBack = () => {
    navigate('/landing');
  };

  return (
    <div className='asset-details-container'>
      {showAlert && (
        <ExtremeAlert
          message="Alert Notification"
          subMessage={`You have ${extremeCount} Very High risks.`}
          onClose={closeAlert}
        />
      )}

      <div className='asset-details-section'>
        <div className='asset-details-first'>
          <h1>{selectedAssetId && selectedAssetName ? `${selectedAssetId} - ${selectedAssetName}` : 'Loading...'}</h1>
          <button
            className='back-button'
            onClick={goBack}
          >
            <img src={backIcon} />
            Hail Creek Mine
          </button>
        </div>

        <div className='asset-details-second'>
          <CustomDropdown
            points={assets}
            setSelectedPoint={handleAssetSelection} // Pass handler to update selected asset
          />

          <div className='background-image-container'>
            {selectedAssetPhoto && (
              <img
                src={`/Files/${selectedAssetPhoto}`}
                onError={(e) => { e.target.onerror = null; e.target.src = `/Files/${selectedAssetPhoto}`; }}
                alt="Asset Photo" />
            )}
          </div>
          <div style={{ display: 'flex', gap: '20px', flexDirection: 'column', }}>
            <RiskRateSection />
            <div className='defect-list'>
              <div className='defect-title'>
                <img src={selectDefect} alt="Select Defect" onClick={handleImageClick} style={{ cursor: 'pointer' }} />
                <h5>Defect List</h5>
              </div>
              {showDropdown && (
                <div
                  style={{
                    position: 'absolute',
                    top: '224px',
                    right: '350px',
                    zIndex: 100,
                    height: '369px',
                    width: '380px',
                    padding: '42px 16px 26px 16px',
                    backgroundColor: '#EBEBEB',
                    borderRadius: '12px',
                    overflowY: 'auto',
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px', }}>
                    <span style={{
                      position: 'absolute',
                      top: '10px',
                      right: '12px',
                      cursor: 'pointer',
                      padding: '4px',
                    }}
                      onClick={() => setShowDropdown(false)}>
                      <img src={filterClose} alt="Cerrar" style={{ width: '24px', height: '24px' }} />
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '14px' }}>
                      <img src={propertyFilter} alt="Select Defect" style={{ height: '20px', width: '20px' }} />
                      <h2 style={{ fontFamily: 'Prometo Trial', fontSize: '20px', fontWeight: '600', color: '#5c5c5c', }}>Property Filter</h2>
                    </div>
                    <div style={{
                      fontFamily: 'Prometo Trial',
                      backgroundColor: '#EBEBEB',
                      color: '#B8A337',
                      cursor: 'pointer',
                      border: 'none',
                      borderBottom: 'solid 1px #B8A337',
                      padding: '1px 0px',
                      margin: '0',
                      lineHeight: '1',
                      display: 'inline-block',
                      alignSelf: 'center',
                      position: 'relative'
                    }}
                      onClick={handleResetAll}>
                      Reset all
                    </div>
                  </div>

                  <div style={{ marginBottom: '24px' }}>
                    <label htmlFor="risk_rate" style={{ display: 'block', marginBottom: '16px', fontFamily: 'Prometo Trial', fontSize: '16px', fontWeight: '500', color: '#767676' }}>
                      Risk Rate
                    </label>
                    <DefectListSelect
                      options={Array.from(new Set(filteredDefects.map((defect) => defect.risk_rate)))} // Extract and display only unique risk_rate
                      value={selectedRiskRate} // Set the current value of the select
                      onChange={(value) => handleFilterChange({ target: { name: "risk_rate", value } })} // Handle change
                      placeholder="Select an option..."
                    />
                  </div>
                  <div>
                    <label htmlFor="defect_type" style={{ display: 'block', marginBottom: '16px', fontFamily: 'Prometo Trial', fontSize: '16px', fontWeight: '500', color: '#767676' }}>
                      Defect Type
                    </label>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                      {defectTypes.map((defect) => ( // Iterate on defect types
                        <button
                          key={defect}
                          style={{
                            fontFamily: 'Arboria Book',
                            padding: '8px',
                            fontSize: '13px',
                            color: '#A1A1A1',
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            border: 'none',
                            cursor: 'pointer',
                            color: selectedDefectTypes.includes(defect) ? '#767676' : '#A1A1A1',
                            backgroundColor: selectedDefectTypes.includes(defect) ? '#C0C0C0' : 'white',
                          }}
                          onClick={() => handleDefectTypeChange(defect)}
                        >
                          {defect}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <ul>
                {filteredDefects.map((defect) => (
                  <li
                    key={defect.defect_id}
                    onClick={() => navigate(`/defect-details/${defect.defect_id}`)}
                  >
                    <RiskIcon
                      riskLevel={defect.risk_rate}
                      size={24}
                    />
                    <div>
                      <h6>{defect.defect_id}</h6>
                      <p>{defect.risk_rate}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

          </div>


        </div>
      </div>
    </div>
  );
}

export default AssetDetails;