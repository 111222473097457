import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';

import CustomAlert from '../components/CustomAlert';

function LoginPage(){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState(null); 
    const navigate = useNavigate();

    const logInUser = (e) => {
      e.preventDefault();
      axios.post("https://www.mincka-aim.com/api/login", {
          email: email,
          password: password
      })
      .then(function (response) {
          localStorage.setItem("token", response.data.token);
          navigate('/landing');
      })
      .catch(function (error) {
          console.log(error, 'error');
          if (error.response.status === 401) {
              setAlert({ message: 'Invalid credentials', type: 'error' });
          } else {
              setAlert({ message: 'An error occurred', type: 'error' });
          }
      });
  };
  
    return (
        <div className="login-container App">
          {alert && <CustomAlert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />}
          <div className="login-background">
            <div className="login-box">
              <h4>Log In</h4>
              <form onSubmit={logInUser}>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="login-button">Log in</button>
              </form>
              <Link to="/get-account" className="get-account-button">Get an account</Link>
            </div>
          </div>
        </div>
      );
}

export default LoginPage;