import React, { createContext, useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';

export const AssetContext = createContext();

export const AssetProvider = ({ children }) => {
  const [assets, setAssets] = useState([]);
  const [defects, setDefects] = useState([]);

  // Function to save updated defects
  const saveDefectsToExcel = async (updatedDefects) => {
    try {
      // Create the workbook
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(updatedDefects);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Defects');

      // Write the file to a Blob
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Create a Blob from the binary array
      const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const formData = new FormData();
      formData.append('file', blob, 'defect_list.xlsx');

      // Send the file to the server
      const response = await axios.post("https://www.mincka-aim.com/api/upload-defects", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data.message);

      return true;
    } catch (error) {
      console.error('Error saving defect list:', error);
      return false;
    }
  };

  // Load data from asset_list.xlsx
  useEffect(() => {
    const loadAssetData = async () => {
      try {
        const response = await fetch('/data/asset_list.xlsx');
        if (!response.ok) {
          throw new Error('Error loading Excel file');
        }
        const data = await response.arrayBuffer();
        const workbook = XLSX.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setAssets(jsonData);
      } catch (error) {
        console.error('Error loading or processing Excel file:', error);
      }
    };

    loadAssetData();
  }, []);

  // Load data from defect_list.xlsx
  useEffect(() => {
    const loadDefectData = async () => {
      try {
        const response = await fetch('/data/defect_list.xlsx');
        const data = await response.arrayBuffer();
        const workbook = XLSX.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setDefects(jsonData);
      } catch (error) {
        console.error('Error loading defect list:', error);
      }
    };

    loadDefectData();
  }, []);

  return (
    <AssetContext.Provider value={{
      assets,
      defects,
      setDefects,
      saveDefectsToExcel
    }}>
      {children}
    </AssetContext.Provider>
  );
};
