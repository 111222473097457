import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AssetContext } from '../contexts/AssetListContext.js';
import backIcon from '../assets/icons/navigate_before.svg';
import graphics from '../assets/icons/graphics.svg';
import filterIcon from '../assets/icons/down_arrow.svg';

// Mapping for job status
const JOB_STATUS_CONFIG = {
    'Not Status Set': '#F7F7F7',
    'Not Actioned': '#B11C03',
    'Notification Raised': '#E86A02',
    'Waiting on Information / Engineering': '#FE9844',
    'Work Order Created': '#DAF2D0',
    'Planning in Progress': '#B5E6A2',
    'Work Planned': '#8ED973',
    'Work Completed': '#4EA42C'
};

// Mapping for job completion rates
const JOB_COMPLETION_RATES = {
    'Not Status Set': '0%',
    'Not Actioned': '0%',
    'Notification Raised': '10%',
    'Waiting on Information / Engineering': '20%',
    'Work Order Created': '30%',
    'Planning in Progress': '40%',
    'Work Planned': '50%',
    'Work Completed': '100%'
};

const RemedialActions = () => {
    const { defects, setDefects, saveDefectsToExcel = () => { } } = useContext(AssetContext);
    console.log(defects[0])
    const navigate = useNavigate();
    const [colors, setColors] = useState({});
    const [filters, setFilters] = useState(() => {
        const savedFilters = localStorage.getItem('tableFilters');
        return savedFilters ? JSON.parse(savedFilters) : {};
    });
    const [filteredDefects, setFilteredDefects] = useState(defects);
    const [activeFilter, setActiveFilter] = useState(null);
    const [showColumnDropdown, setShowColumnDropdown] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [editValue, setEditValue] = useState('');
    const statusDropdownRef = useRef(null);

    const [columns, setColumns] = useState(() => {
        const savedColumns = localStorage.getItem('tableColumns');
        if (savedColumns) {
            return JSON.parse(savedColumns);
        }
        return [
            { field: 'asset_name', label: 'Asset', visible: true, showFilter: true },
            { field: 'defect_id', label: 'Defect', visible: true, showFilter: false },
            { field: 'risk_rate', label: 'Risk Level', visible: true, showFilter: true },
            { field: 'remedial action description', label: 'Remedial action description', visible: true, showFilter: false },
            { field: 'Task type', label: 'Task Type', visible: true, showFilter: true },
            { field: 'Cost ($ AUD)', label: 'Cost', visible: true, showFilter: false },
            { field: 'remediation timeframe', label: 'Remediation timeframe', visible: true, showFilter: false },
            { field: 'Work Order N°/Notification N°', label: 'Work Order N°/Notification N°', visible: true, showFilter: false },
            { field: 'Job Status', label: 'Job Status', visible: true, showFilter: false },
            { field: 'Job Completion Rate', label: 'Job Completion Rate', visible: true, showFilter: false },
            { field: 'Commentary', label: 'Commentary', visible: true, showFilter: false },
        ];
    });

    // Save column state to localStorage when it changes
    useEffect(() => {
        localStorage.setItem('tableColumns', JSON.stringify(columns));
    }, [columns]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusDropdownRef.current && !statusDropdownRef.current.contains(event.target)) {
                setEditingId(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const ColumnVisibilityDropdown = () => (
        <div className="column-visibility-container">
            <button
                className="hide-show-button"
                onClick={() => setShowColumnDropdown(!showColumnDropdown)}
            >
                <div className="button-content">
                    <h6 style={{ fontSize: '16px' }}>Hide/Show Column</h6>
                    <img
                        src={filterIcon}
                        alt="Toggle columns"
                        className={`dropdown-arrow ${showColumnDropdown ? 'rotated' : ''}`}
                    />
                </div>
            </button>

            {showColumnDropdown && (
                <div className="column-dropdown">
                    <div className="dropdown-content">
                        {columns.map((column, index) => (
                            <div key={index} className="column-option">
                                <label className="checkbox-label">
                                    <input
                                        type="checkbox"
                                        checked={column.visible}
                                        onChange={() => toggleColumnVisibility(index)}
                                        className="checkbox-input"
                                    />
                                    <span className="checkbox-text">{column.label}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            const container = document.querySelector('.column-visibility-container');
            if (container && !container.contains(event.target)) {
                setShowColumnDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const toggleColumnVisibility = (columnIndex) => {
        setColumns(prevColumns => prevColumns.map((column, index) =>
            index === columnIndex ? { ...column, visible: !column.visible } : column
        ));
    };

    const getUniqueValues = (field) => {
        const relevantFilters = Object.fromEntries(
            Object.entries(filters).filter(([key]) => key !== field)
        );

        const filteredForField = defects.filter(defect =>
            Object.entries(relevantFilters).every(([key, values]) => {
                if (!values || values.length === 0) return true;
                const defectValue = String(defect[key] || '').toLowerCase();
                return values.some(value =>
                    String(value).toLowerCase() === defectValue
                );
            })
        );

        return [...new Set(filteredForField.map(defect => defect[field]))].filter(Boolean);
    };

    useEffect(() => {
        const riskColors = {
            'Very High': getCssVariableValue('--risk-color-4'),
            'High': getCssVariableValue('--risk-color-3'),
            'Medium': getCssVariableValue('--risk-color-2'),
            'Low': getCssVariableValue('--risk-color-1'),
        };
        setColors(riskColors);
    }, []);

    useEffect(() => {
        filterData();
    }, [filters, defects]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.filter-dropdown') && !event.target.closest('.filter-icon')) {
                setActiveFilter(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const getCssVariableValue = (variableName) => {
        return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
    };

    const goBack = () => {
        navigate('/analytics');
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD'
        }).format(value);
    };

    const handleFilterClick = (field) => {
        setActiveFilter(activeFilter === field ? null : field);
    };

    useEffect(() => {
        localStorage.setItem('tableFilters', JSON.stringify(filters));
    }, [filters]);

    const handleFilterSelect = (field, value) => {
        setFilters(prev => {
            const currentValues = prev[field] || [];
            const valueIndex = currentValues.indexOf(value);

            let newValues;
            if (valueIndex === -1) {
                newValues = [...currentValues, value];
            } else {
                newValues = currentValues.filter((_, index) => index !== valueIndex);
            }

            const newFilters = {
                ...prev,
                [field]: newValues
            };

            // Si el array está vacío, eliminar la propiedad
            if (newFilters[field].length === 0) {
                delete newFilters[field];
            }

            // Guardar en localStorage
            localStorage.setItem('tableFilters', JSON.stringify(newFilters));
            return newFilters;
        });
    };

    const clearFilter = (field) => {
        setFilters(prev => {
            const newFilters = { ...prev };
            delete newFilters[field];
            // Guardar en localStorage
            localStorage.setItem('tableFilters', JSON.stringify(newFilters));
            return newFilters;
        });
    };

    const filterData = () => {
        const filtered = defects.filter(defect => {
            return Object.entries(filters).every(([key, values]) => {
                if (!values || values.length === 0) return true;
                const defectValue = String(defect[key] || '').toLowerCase();
                return values.some(value =>
                    String(value).toLowerCase() === defectValue
                );
            });
        });
        setFilteredDefects(filtered);
    };

    const FilterDropdown = ({ field }) => {
        const options = getUniqueValues(field);
        const selectedValues = filters[field] || [];

        return (
            <div className="">
                <button
                    className='filter-button'
                    onClick={() => handleFilterClick(field)}
                >
                    <img
                        src={filterIcon}
                        alt="Filter"
                    />
                    {selectedValues.length > 0 && (
                        <span className="filter-count">{selectedValues.length}</span>
                    )}
                </button>

                {activeFilter === field && (
                    <div className="filter-dropdown">
                        <div className="select-options-container">
                            <div className='select-options'>
                                <button
                                    className="clear-filter-button"
                                    onClick={() => clearFilter(field)}
                                >
                                    Clear filter
                                </button>
                                {options.map((option, index) => (
                                    <button
                                        key={index}
                                        className={`filter-option ${selectedValues.includes(option) ? 'selected-option' : ''}`}
                                        onClick={() => handleFilterSelect(field, option)}
                                    >
                                        <span className="option-text">{option}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderHeader = (column) => (
        <th key={column.field}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{column.label}</span>
                {column.showFilter && <FilterDropdown field={column.field} />}
            </div>
        </th>
    );

    const handleEdit = (defectId, field, currentValue) => {
        setEditingId({ id: defectId, field });
        setEditValue(currentValue || '');
    };

    const handleStatusClick = (defectId, field, currentEditingId) => {
        // If there is already a cell being edited
        if (currentEditingId) {
            if (currentEditingId.id === defectId && currentEditingId.field === field) {
                setEditingId(null);
                return;
            }
            setEditingId({ id: defectId, field });
            return;
        }
        setEditingId({ id: defectId, field });
    };

    const handleSave = async (defectId, field) => {
        const updatedDefects = defects.map(defect => {
            if (defect.defect_id === defectId) {
                return {
                    ...defect,
                    [field]: editValue,
                };
            }
            return defect;
        });

        if (setDefects) {
            setDefects(updatedDefects);
        }

        // Apply existing filters to the updated set of defects
        const filtered = updatedDefects.filter(defect =>
            Object.entries(filters).every(([key, values]) => {
                if (!values || values.length === 0) return true;
                const defectValue = String(defect[key] || '').toLowerCase();
                return values.some(value =>
                    String(value).toLowerCase() === defectValue
                );
            })
        );
        setFilteredDefects(filtered);

        const success = await saveDefectsToExcel(updatedDefects);
        if (success) {
            console.log('Defects saved successfully');
        } else {
            console.error('Error saving defects');
        }

        setEditingId(null);
    };

    const handleStatusSelect = async (defectId, status) => {
        const updatedDefects = defects.map(defect => {
            if (defect.defect_id === defectId) {
                return {
                    ...defect,
                    'Job Status': status,
                    'Job Completion Rate': JOB_COMPLETION_RATES[status] // Automatically update completion rate
                };
            }
            return defect;
        });

        if (setDefects) {
            setDefects(updatedDefects);
        }

        setFilteredDefects(updatedDefects.filter(defect =>
            Object.entries(filters).every(([key, values]) => {
                if (!values || values.length === 0) return true;
                const defectValue = String(defect[key] || '').toLowerCase();
                return values.some(value =>
                    String(value).toLowerCase() === defectValue
                );
            })
        ));

        const success = await saveDefectsToExcel(updatedDefects);
        if (success) {
            console.log('Status and completion rate updated successfully');
        } else {
            console.error('Error updating status and completion rate');
        }

        setEditingId(null);
    };

    const handleKeyPress = (e, defectId, field) => {
        if (e.key === 'Enter') {
            handleSave(defectId, field);
        } else if (e.key === 'Escape') {
            setEditingId(null);
        }
    };

    const StatusDropdown = ({ defectId, currentStatus }) => {
        const handleOptionClick = (e, status) => {
            e.stopPropagation();
            handleStatusSelect(defectId, status);
        };

        return (
            <div className="status-dropdown" onClick={e => e.stopPropagation()}>
                {Object.entries(JOB_STATUS_CONFIG).map(([status, color]) => (
                    <div
                        key={status}
                        className="status-option"
                        style={{ backgroundColor: color }}
                        onClick={(e) => handleOptionClick(e, status)}
                    >
                        {status}
                    </div>
                ))}
            </div>
        );
    };
    const renderCell = (defect, column) => {
        const isEditing = editingId && editingId.id === defect.defect_id && editingId.field === column.field;

        if (column.field === 'Job Status') {
            const currentStatus = defect[column.field];
            const backgroundColor = currentStatus ? JOB_STATUS_CONFIG[currentStatus] : 'transparent';
            // Determine text color based on the current status
            const textColor = currentStatus === 'Not Actioned' 
            ? 'white' 
            : currentStatus 
                ? 'black' 
                : 'gray'; 
            return (
                <div className="status-cell-container">
                    <div
                        className={`status-cell ${isEditing ? 'active' : ''}`}
                        style={{ backgroundColor, color: textColor, }}
                        onClick={(e) => {
                            handleStatusClick(defect.defect_id, column.field, editingId);
                        }}
                    >
                        {currentStatus || 'Select status'}
                    </div>
                    {isEditing && (
                        <StatusDropdown
                            defectId={defect.defect_id}
                            currentStatus={currentStatus}
                        />
                    )}
                </div>
            );
        }

        if (column.field === 'Job Completion Rate') {
            const currentStatus = defect['Job Status'];
            const backgroundColor = currentStatus ? JOB_STATUS_CONFIG[currentStatus] : 'transparent';
                // Determine text color based on the current status
            const percentColor = currentStatus === 'Not Actioned' 
            ? 'white' 
            : currentStatus 
                ? 'black' 
                : 'gray'; 
            
            return (
                <div
                    className="status-cell"
                    style={{ backgroundColor, color: percentColor, }}
                >
                    {defect[column.field]}
                </div>
            );
        }

        if (column.field === 'Commentary') {
            return isEditing ? (
                <textarea
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    onBlur={() => handleSave(defect.defect_id, column.field)}
                    onKeyDown={(e) => {
                        // Permitir múltiples líneas, solo guardar con Ctrl/Cmd + Enter
                        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
                            handleSave(defect.defect_id, column.field);
                        } else if (e.key === 'Escape') {
                            setEditingId(null);
                        }
                    }}
                    className="editable-cell-textarea"
                    rows={4}
                    placeholder="Enter your comment here..."
                />
            ) : (
                <div
                    className="editable-cell commentary"
                    style={{ color: defect[column.field] ? 'black' : 'gray',
                        width: '200px',
                        wordWrap: 'break-word',
                        overflowWrap: 'anywhere',
                        whiteSpace: 'normal' }}
                    onClick={() => handleEdit(defect.defect_id, column.field, defect[column.field])}
                >
                    {defect[column.field] || 'Click to edit'}
                </div>
            );
        } else if (column.field === 'Work Order N°/Notification N°') {
            return isEditing ? (
                <input
                    type="text"
                    value={editValue}
                    onChange={(e) => setEditValue(e.target.value)}
                    onBlur={() => handleSave(defect.defect_id, column.field)}
                    onKeyDown={(e) => handleKeyPress(e, defect.defect_id, column.field)}
                    className="editable-cell-input"
                />
            ) : (
                <div
                    className="editable-cell"
                    style={{ color: defect[column.field] ? 'black' : 'gray',
                        width: '200px',
                        wordWrap: 'break-word',
                        overflowWrap: 'anywhere',
                        whiteSpace: 'normal' }}
                    onClick={() => handleEdit(defect.defect_id, column.field, defect[column.field])}
                >
                    {defect[column.field] || 'Click to edit'}
                </div>
            );
        }
        // Handle other special cell types as before
        if (column.field === 'risk_rate') {
            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: '6px', flexWrap: 'wrap' }}>
                    <span
                        style={{
                            backgroundColor: colors[defect.risk_rate],
                            height: '15px',
                            width: '15px',
                            borderRadius: '4px',
                        }}
                    />
                    {defect[column.field]}
                </div>
            );
        }
        if (column.field === 'defect_id') {
            return (
                <div onClick={() => navigate(`/defect-details/${defect.defect_id}`)}>
                    {defect[column.field]}
                </div>
            );
        }
        if (column.field === 'Cost ($ AUD)') {
            return formatCurrency(defect[column.field]);
        }
        return defect[column.field];
    };

    return (
        <div className='cost-analysis-container'>
            <div className='cost-analytics-title'>
                <div>
                    <h2>Analytics</h2>
                    <Link to="/cost-analysis" className='analysis-button'>
                        Cost Analysis
                    </Link>
                    <Link to="" className='analysis-button-active'>
                        Remedial Actions
                    </Link>
                </div>
                <div style={{ display: 'flex', alignContent: 'center' }}>
                    <ColumnVisibilityDropdown />
                    <Link to="/graphics" className='graphics-button'>
                        <img src={graphics} className="button-image" alt="Graphics" />
                        <div style={{ display: 'block' }}>
                            <h6 style={{ fontSize: '16px' }}>Action Tasks</h6>

                        </div>
                    </Link>
                    <button className='back-button' style={{ width: '100px' }} onClick={goBack}>
                        <img src={backIcon} alt="Back" />
                        Analytics
                    </button>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        {columns
                            .filter(column => column.visible)
                            .map(column => renderHeader(column))}
                    </tr>
                </thead>
                <tbody>
                    {filteredDefects.map((defect, index) => (
                        <tr key={index}>
                            {columns
                                .filter(column => column.visible)
                                .map(column => (
                                    <td key={column.field}>
                                        {renderCell(defect, column)}
                                    </td>
                                ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RemedialActions;